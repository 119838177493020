import React from 'react';
import styled from 'styled-components';
import SocialMedia from '../SocialMedia/socialMedia';


const FooterWrapper = styled.div`    
    display: flex;
    justify-content: flex-end;    
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 6vh;    
    color: white;
    text-align: center;

    ${({ theme }) => theme.media.phone}{        
        padding-bottom: 20px;
        justify-content: center;        
        background-color: rgba(0,0,0, 0.6);        
        height: 8vh;
    }
`;

const Footer =()=>(
    <FooterWrapper>
        <SocialMedia isWhite  showNewsletter />
    </FooterWrapper>
)
export default Footer;