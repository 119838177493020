import { Link } from "gatsby"
import React from "react"
import styled from 'styled-components';


const MenuWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 8vh;
    background-color: rgba(0,0,0,0.8);
    
    
`

const ItemWrapper = styled.div`
    display: flex;
    width: 30%;
    height: 100%;
    justify-content: space-around;
    font-size: 0.8em;

    ${({ theme }) => theme.media.phone}{
        width: 100%;
    }

    ${({ theme }) => theme.media.tablet}{
        width: 100%;
    }
`

const MenuItem = styled.button`
    display: flex;
    align-items: center;
    color: white;
    text-decoration: none;
    font-size: 20px;
    color:white;
    font-size: 0.8em;

    &:hover{    
        font-weight:bold;
        color: white;
        text-decoration: underline;
        text-underline-position: under;    
    }

    ${({ theme }) => theme.media.tablet}{
        font-size: 0.6em;
    }

    ${({ theme }) => theme.media.phone}{
        font-size: 0.6em;
    }
`

const Menu = (props) => (
    <MenuWrapper>
        <ItemWrapper>
            {props.showHome ? <MenuItem as ={Link} to="/" >home</MenuItem> : null }
             {/* <MenuItem as ={Link} to="/about" >about</MenuItem> */}
            <MenuItem as ={Link} to="/music" >muzyka</MenuItem>
            {/* <MenuItem as ={Link} to="https://boguszmusic.cupsell.pl/" >sklep</MenuItem> */}
            {/* <MenuItem as ={Link} to="/events" >events</MenuItem>  */}
            <MenuItem as ={Link} to="/contact" >kontakt</MenuItem>
        </ItemWrapper>
    </MenuWrapper>
)

export default Menu;