import React from 'react';
import { Link } from "gatsby"
import styled from 'styled-components';
import facebookIcon from '../../assets/svgs/facebook.svg';
import facebookIconWhite from '../../assets/svgs/facebook_white.svg';
import { OutboundLink } from "gatsby-plugin-google-analytics"

const FbIcon = styled(facebookIcon)`
    height: 42px;
    width: 42px;

    ${({ theme }) => theme.media.phone}{
        height: 35px;
        width: 35px;
    }
`

const FbIconWhite = styled(facebookIconWhite)`
    height: 42px;
    width: 42px;

    ${({ theme }) => theme.media.phone}{
        height: 35px;
        width: 35px;
    }
`

const Facebook = (props) => (
    <OutboundLink href="https://www.facebook.com/boguszmusic/" target="_blank">
        {props.isWhite ? <FbIconWhite /> : <FbIcon />}
    </OutboundLink>
)

export default Facebook;