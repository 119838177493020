import React from 'react';
import { Link } from "gatsby"
import styled from 'styled-components';
import instagramIcon from '../../assets/svgs/instagram.svg';
import instagramIconWhite from '../../assets/svgs/instagram_white.svg';
import { OutboundLink } from "gatsby-plugin-google-analytics"

const IgIcon = styled(instagramIcon)`
    height: 42px;
    width: 42px;

    ${({ theme }) => theme.media.phone}{
        height: 35px;
        width: 35px;
    }
`

const IgIconWhite = styled(instagramIconWhite)`
    height: 42px;
    width: 42px;

    ${({ theme }) => theme.media.phone}{
        height: 35px;
        width: 35px;
    }
`

const Instagram = (props) => (
    <OutboundLink href="https://www.instagram.com/boguszmusic/" target="_blank">
        {props.isWhite ? <IgIconWhite /> : <IgIcon />}
    </OutboundLink>
)

export default Instagram;