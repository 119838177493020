import React from 'react';
import styled from 'styled-components';
import Facebook from '../Icons/facebook';
import Instagram from '../Icons/instagram'
import Spotify from '../Icons/spotify';
import YouTube from '../Icons/youtube';
import Celebration from '../Icons/celebration'


const SocialMediaWrapper = styled.div`
display: flex;
display: -webkit-box;
display:-webkit-flex;   
justify-content: space-around;
width: 300px;
height: 100%;

${({ theme }) => theme.media.tablet}{
        font-size: 1.0em;       
    }

    ${({ theme }) => theme.media.phone}{
        margin-top:15px;         
    }

`

const Item = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 40px;

`

const SocialMedia = (props) => (
    <SocialMediaWrapper>
        {props.showNewsletter ? <Item><Celebration isWhite={props.isWhite}/></Item> : null}
        <Item><Facebook isWhite={props.isWhite}/></Item>
        <Item><Instagram isWhite={props.isWhite}/> </Item>
        <Item><Spotify isWhite={props.isWhite}/> </Item>
        <Item><YouTube isWhite={props.isWhite}/> </Item>
    </SocialMediaWrapper>
)

export default SocialMedia;