import React from 'react';
import { Link } from "gatsby"
import styled from 'styled-components';
import celebrationWh from '../../assets/svgs/celebration_white.svg';
import celebrationBk from '../../assets/svgs/celebration.svg';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';

const CbIconWhite = styled(celebrationWh)`
    height: ${(props) => props.isSmall ? '25px' : '42px'};
    width: ${(props) => props.isSmall ? '25px' : '42px'};
    padding: 5px;


    ${({ theme }) => theme.media.phone}{
        height: 35px;
        width: 35px;
    }
`

const CbIconBk = styled(celebrationBk)`
    height: 42px;
    width: 42px;

    ${({ theme }) => theme.media.phone}{
        height: 35px;
        width: 35px;
    }
`

// export const CelebrationWhite = () => (
//     <CbIconWhite />
// )

const Celebration = (props) => (
    <Link onClick={e => {
        e.preventDefault()
        trackCustomEvent({
            category: "Newsletter",
            action: "Click",
            label: "From footer",
            hitCallback: function () {
                document.location = "/newsletter"
            }
        })
    }} to="/newsletter" >
        {props.isWhite ? <CbIconWhite /> : <CbIconBk />}
    </Link>
)

export default Celebration;