import React from 'react';
import { Link } from "gatsby"
import styled from 'styled-components';
import spotifyIcon from '../../assets/svgs/spotify.svg';
import spotifyIconWhite from '../../assets/svgs/spotify_white.svg';
import { OutboundLink } from "gatsby-plugin-google-analytics"

const SpIcon = styled(spotifyIcon)`
    height: 42px;
    width: 42px;

    ${({ theme }) => theme.media.phone}{
        height: 35px;
        width: 35px;
    }
`


const SpIconWhite = styled(spotifyIconWhite)`
    height: 42px;
    width: 42px;

    ${({ theme }) => theme.media.phone}{
        height: 35px;
        width: 35px;
    }
`

const Spotify = (props) => (
    <OutboundLink href="https://open.spotify.com/artist/1h38eYp11ADg0VFGw4qF5A" target="_blank">
        {props.isWhite ? <SpIconWhite /> : <SpIcon />}
    </OutboundLink>
)

export default Spotify;