import React from 'react';
import { Link } from "gatsby"
import styled from 'styled-components';
import youtubeIcon from '../../assets/svgs/youtube.svg';
import youtubeIconWhite from '../../assets/svgs/youtube_white.svg';
import { OutboundLink } from "gatsby-plugin-google-analytics"

const YtIcon = styled(youtubeIcon)`
    height: 42px;
    width: 42px;

    ${({ theme }) => theme.media.phone}{
            height: 35px;
            width: 35px;
    }
`

const YtIconWhite = styled(youtubeIconWhite)`
    height: 42px;
    width: 42px;

    ${({ theme }) => theme.media.phone}{
            height: 35px;
            width: 35px;
    }
`

const YouTube = (props) => (
    <OutboundLink href="https://www.youtube.com/channel/UCvKc7s93qxqpZ0X2FNouYpA" target="_blank">
        {props.isWhite ? <YtIconWhite /> : <YtIcon />}
    </OutboundLink>
)

export default YouTube;